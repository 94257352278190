<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
	name: "TiContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("ti_home")) {
				m.push({
					titulo: "Painel TI",
					titulo_app: "TI",
					icon: "mdi-monitor-dashboard",
					to: "/ti",
					tooltip: "Página inicial do T.I",
				});
			}

			if (this.permissao("ti_dicionario_senior")) {
				m.push({
					titulo: "Dicionário Sênior",
					titulo_app: "Dicionário",
					icon: "mdi-database-search-outline",
					to: "/ti/dicionario-senior",
					tooltip:
						"Dicionário de banco de dados das tabelas do sistema Sênior",
				});
			}

			if (this.permissao("ti_notas_versao")) {
				m.push({
					titulo: "Notas de versão",
					titulo_app: "Versão",
					icon: "mdi-xml",
					to: "/ti/notas-de-versao",
					tooltip: "Lista de versões e alterações do sistema Gestor",
				});
			}

			if (this.permissao("ti_cronjob")) {
				m.push({
					titulo: "Cronjob",
					titulo_app: "Cronjob",
					icon: "mdi-timer-play-outline",
					to: "/ti/cronjob",
					tooltip: "Página de gerenciamento de processos do Cronjob",
				});
			}

			if (this.permissao("ti_cronjob")) {
				m.push({
					titulo: "Cron DB2 Comissão",
					titulo_app: "Cron DB2 Comissão",
					icon: "mdi-timer-play-outline",
					to: "/ti/cronjob/db2",
					tooltip: "Página de logs de cronjob DB2 comissão",
				});
			}

			if (this.permissao("ti_banco_ciss")) {
				m.push({
					titulo: "Banco CISS",
					titulo_app: "CISS",
					icon: "mdi-timer-play-outline",
					to: "/ti/ciss",
					tooltip:
						"Página de acompanhamento de conexões do banco CISS",
				});
			}

			if (this.permissao("ti_calculos")) {
				m.push({
					titulo: "Cálculos",
					titulo_app: "Cálculos",
					icon: "mdi-timer-play-outline",
					to: "/ti/calculos",
					tooltip: "Cálculos usados frequentemente",
				});
			}

			if (this.permissao("ti_bi")) {
				m.push({
					titulo: "Power BI",
					titulo_app: "Power BI",
					icon: "mdi-chart-bar-stacked",
					to: "/ti/bi",
					tooltip: "Dashboard de Power BI",
				});
			}

			if (this.permissao("ti_acertos")) {
				m.push({
					titulo: "Acertos",
					titulo_app: "Acertos",
					icon: "mdi-chart-bar-stacked",
					to: "/ti/acertos",
					tooltip: "Tela de acertos contábeis",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>